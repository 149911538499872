import axios from "axios";

export default ({ getStatsUrl, allStats = {}, initDateRange = "365d" }) => ({
    loadingStatsFor: null,
    allStats: allStats,
    segment: "industry",
    date_range: initDateRange,
    supported_date_ranges: {
        "7d": "week",
        "30d": "month",
        "365d": "year",
    },
    charts: [],

    init() {
        //this.changeDateRange("365d");
        this.initTopIndustriesChart();
        this.initTopLocationsChart();
        this.updateChartData();
        // this.initCompareCompetitorsChart();
        //
        // this.$watch("segment", () => {
        //     this.updateCompareCompetitorsChart();
        // });
    },
    get stats() {
        return this.allStats[this.date_range] ?? null;
    },
    get isLoading() {
        return this.loadingStatsFor !== null;
    },
    async changeDateRange(span) {
        if (this.isLoading) {
            return; // Hold your horses
        }

        if (span in this.allStats) {
            this.date_range = span;
            this.updateChartData();
            return;
        }

        this.loadingStatsFor = span;
        try {
            const response = await axios.get(`${getStatsUrl}?span=${span}`);
            this.allStats[span] = response.data.stats;
            this.date_range = span;
            this.updateChartData();
        } catch (error) {
            console.log(error);
            alert("Sorry, that didn't work");
        }
        this.loadingStatsFor = null;
    },

    numberToOrdinal(n) {
        const s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },

    updateChartData() {
        this.updateTopIndustriesChart();
        this.updateTopLocationsChart();
        // this.updateCompareCompetitorsChart();
    },

    // --- Charts!
    initTopIndustriesChart() {
        // Between Apex v3.35.0 and v3.36.0 a change seems to have been made that is causing the pie charts to render too large
        // Given it's still not fixed, it's probably conflicting with the wrapping elements in my code but i've wasted so much time on these charts already
        // So i'm cheating, and calcing the height myself.
        const canvas = document.getElementById("chart_top_industries");
        const height = canvas.parentNode.offsetHeight;
        console.log("ind", height);

        const options = {
            series: [],
            chart: {
                type: "pie",
                height,
            },
            legend: {
                position: "left",
            },
            labels: [],
            noData: {
                text: "Sorry, we don't have enough data to report on this yet.",
                style: {
                    color: "#64748b",
                    fontSize: "14px",
                },
            },
            plotOptions: {
                pie: {
                    dataLabels: { offset: -20 },
                },
            },
        };

        this.charts["top_industries"] = new ApexCharts(canvas, options);
        this.charts["top_industries"].render();
    },

    updateTopIndustriesChart() {
        const data = Object.values(Alpine.raw(this.stats.top_comparison_types["by_industry"]))
            .sort((a, b) => b.volume - a.volume)
            .slice(0, 5);

        this.charts["top_industries"].updateOptions(
            {
                labels: data.map((i) => i.name),
                series: data.map((i) => i.volume),
            },
            true,
            true,
        );
    },

    initTopLocationsChart() {
        const canvas = document.getElementById("chart_top_locations");

        const height = canvas.parentNode.offsetHeight;
        console.log("loc", height);
        const options = {
            series: [],
            chart: {
                type: "pie",
                height,
                // width: "100%",
            },
            padding: 0,

            legend: {
                position: "left",
            },
            labels: [],
            noData: {
                text: "Sorry, we don't have enough data to report on this yet.",
                style: {
                    color: "#64748b",
                    fontSize: "14px",
                },
            },
            plotOptions: {
                pie: {
                    dataLabels: { offset: -20 },
                },
            },
        };

        this.charts["top_locations"] = new ApexCharts(canvas, options);
        this.charts["top_locations"].render();
    },

    updateTopLocationsChart() {
        const data = Object.values(Alpine.raw(this.stats.top_comparison_types["by_location"]));

        this.charts["top_locations"].updateOptions(
            {
                labels: data.map((l) => l.name),
                series: data.map((l) => l.volume),
            },
            true,
            true,
        );
    },

    // initCompareCompetitorsChart() {
    //     const canvas = document.getElementById("chart_compare_competitors");
    //
    //     //console.log("loc", height);
    //     const options = {
    //         series: [],
    //         chart: {
    //             type: "bar",
    //             // stacked: true,
    //             // height,
    //             width: "100%",
    //             toolbar: {
    //                 show: false,
    //             },
    //         },
    //         plotOptions: {
    //             bar: {
    //                 horizontal: true,
    //             },
    //         },
    //         legend: {
    //             //show: false,
    //         },
    //         tooltip: {
    //             shared: true,
    //             intersect: false,
    //             x: {
    //                 show: false, // hide the industry/location title
    //             },
    //             fixed: {
    //                 enabled: true,
    //                 position: "topRight",
    //             },
    //         },
    //         noData: { text: "Loading..." },
    //         grid: {
    //             show: false,
    //         },
    //         dataLabels: {
    //             formatter: (val, { seriesIndex, dataPointIndex, w }) => {
    //                 //console.log(w.config.series[seriesIndex]);
    //                 return "";
    //             },
    //         },
    //         yaxis: {
    //             showForNullSeries: false,
    //         },
    //     };
    //
    //     this.charts["compare_competitors"] = new ApexCharts(canvas, options);
    //     this.charts["compare_competitors"].render();
    // },
    //
    // updateCompareCompetitorsChart() {
    //     const data = Object.values(Alpine.raw(this.stats.top_competitors["by_" + this.segment]));
    //
    //     // Categories = Industry or Location
    //     const categories = data.map((arr) => arr.name);
    //
    //     // Create a list of vendors
    //     let series = data.reduce((acc, cur) => {
    //         (cur.vendors ?? []).forEach((v) => {
    //             if (!(v.id in acc)) {
    //                 acc[v.id] = { name: v.name, data: new Array(categories.length).fill(null) };
    //             }
    //         });
    //         return acc;
    //     }, {});
    //
    //     Object.entries(data).forEach(([index, arr]) => {
    //         (arr.vendors ?? []).forEach((v) => {
    //             series[v.id].data[index] = v.volume;
    //
    //             //vendorScores[v.id] = v.volume;
    //         });
    //     });
    //
    //     // De-key series
    //     series = Object.values(series);
    //
    //     console.log(series);
    //     //return;
    //
    //     // let series = new Array(3).fill(null).map((s) => ({ data: [], vendors: [] }));
    //     // // let categories = [];
    //     //
    //     // data.forEach(function (arr) {
    //     //     series[0]["data"].push(arr.vendors[0]?.volume ?? 0);
    //     //     series[0]["vendors"].push(arr.vendors[0]?.name ?? "");
    //     //     series[1]["data"].push(arr.vendors[1]?.volume ?? 0);
    //     //     series[1]["vendors"].push(arr.vendors[1]?.name ?? "");
    //     //     series[2]["data"].push(arr.vendors[2]?.volume ?? 0);
    //     //     series[2]["vendors"].push(arr.vendors[2]?.name ?? "");
    //     //     // categories.push(arr.name);
    //     // });
    //
    //     const height = categories.length * 75 + "px";
    //
    //     this.charts["compare_competitors"].updateOptions(
    //         {
    //             series,
    //             chart: {
    //                 height,
    //             },
    //             xaxis: {
    //                 categories,
    //             },
    //         },
    //         true,
    //         true,
    //     );
    // },
});
